<template>
  <svg
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    :class="[customClass.toString()]"
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z"></path>
  </svg>
</template>

<script>
export default {
  name: "IconDownload",
  components: {},
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>
